.App-header {
  max-height: 500px;
  overflow: hidden;
  margin-bottom: 20px;
}
.App-header img {
  width: 100vw;
}

.button-container {
  text-align: center;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  font-weight: bold;
}
.button-container a {
  font-weight: bold;
  font-size: 20px;
}
h2 {
  background-color: blue;
  color: white;
  text-align: center;
  font-weight: bolder;
  padding: 20px;
}
.subheader {
  font-size: 20px;
  text-align: center;
}
.footer {
  background-color: blue;
  color: white;
  padding-bottom: 20px;
  padding-top: 20px;
}
.footer img {
  width: 50px;
}
.footer-header {
  text-align: center;
  padding-bottom: 20px;
}
.footer-text {
  font-size: 12px;
  text-align: justify;
  text-justify: inter-word;
}
